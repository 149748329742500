import * as React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Seo from "../components/seo";
import Layout from "../components/layout";
import WorksEntry from "../components/parts/works-entry";

import "../styles/global/base.scss";
import "../styles/global/_list.scss";
import "../styles/works.scss";

gsap.registerPlugin(ScrollTrigger);

const initialState = {
  member: 'ALL',
  year: 'ALL',
  category: 'ALL'
};

const filterReducer = (state, action) => {
  switch (action.type) {
    case 'member':
      state = {
        member: action.value,
        year: 'ALL',
        category: 'ALL'
      };
      break;
    case 'year':
      state = {
        member: 'ALL',
        year: action.value,
        category: 'ALL'
      };
      break;
    case 'category':
      state = {
        member: 'ALL',
        year: 'ALL',
        category: action.value
      };
      break;
    default:
      break;
  }
  return state;
};

const WorksPage = ({ data }) => {
  const [filteredWorks, setFilteredWorks] = React.useState(data.worksJson.edges);
  const [filterState, filterAction] = React.useReducer(filterReducer, initialState);

  const onMemberChanged = React.useCallback((event) => {
    filterAction({type: 'member', value: event.target.value});
  }, []);

  const onYearChanged = React.useCallback((event) => {
    filterAction({type: 'year', value: event.target.value});
  }, []);

  const onCategoryChanged = React.useCallback((event) => {
    filterAction({type: 'category', value: event.target.value});
  }, []);

  React.useEffect(() => {
    if (filterState.member !== 'ALL') {
      setFilteredWorks(
        data.worksJson.edges.filter((edge) => edge.node.members.includes(filterState.member))
      );
    } else if (filterState.year !== 'ALL') {
      if (filterState.year === '2010') {
        setFilteredWorks(
          data.worksJson.edges.filter((edge) => edge.node.year <= 2010)
        );
      } else {
        setFilteredWorks(
          data.worksJson.edges.filter((edge) => edge.node.year === Number(filterState.year))
        );
      }
    } else if (filterState.category !== 'ALL') {
      setFilteredWorks(
        data.worksJson.edges.filter((edge) => edge.node.category.includes(filterState.category))
      );
    } else {
      setFilteredWorks(data.worksJson.edges);
    }
    const t = gsap.delayedCall(0.2, ScrollTrigger.refresh);
    return (() => {
      t.kill();
    });
  }, [data.worksJson.edges, filterState]);

  /*
  const filteredWorksTitle = React.useCallback((state) => {
    let subTitle;
    if (state.member !== 'ALL') {
      const member = data.membersJson.edges.find((edge) => edge.node.slug === state.member);
      subTitle = member.node.name;
    } else if (state.year !== 'ALL') {
      subTitle = (state.year === '2010') ? '2010以前' : state.year;
    } else if (state.category !== 'ALL') {
      subTitle = state.category;
    } else {
      return null;
    }
    return (
      <React.Fragment>
        <span className="symbol">/</span>
        <span className="worksSubTitle">{subTitle}</span>
      </React.Fragment>
    );
  }, [data.membersJson.edges]);
  */

  return (
    <React.Fragment>
      <Seo title="WORKS" pageClass="worksPage" />
      <Layout>
        <Container fluid className="listHeader">
          <Row>
            <Col xs={12} className="pageTitle">
              <h1>
                <span className="title">WORKS</span>
                {/*filteredWorksTitle(filterState)*/}
              </h1>
            </Col>
            <Col xs={12} className="worksFilter filters">
              <Row>
                <Col xs={12} lg={4}>
                  <div>
                    <h3>Member:</h3>
                    <select value={filterState.member} onChange={onMemberChanged}>
                      <option>ALL</option>
                      <option value="sumita">住田 望</option>
                      <option value="komatsubara">小松原 時夫</option>
                      <option value="serizawa">芹澤 博雅</option>
                      <option value="kubota">久保田 尚</option>
                      <option value="ochiai-masao">落合 正夫</option>
                      <option value="ochiai-tomonari">落合 智成</option>
                      <option value="saitoh">齋藤 桂一</option>
                      <option value="yanagimoto">柳本 歩規</option>
                      <option value="matsuse">松瀬 友樹</option>
                      {/*<option value="sano">佐野 司</option>*/}
                      <option value="kokubo">小久保 尚志</option>
                      <option value="ishikawa">石川 陽介</option>
                      <option value="ookawara">大河原 祐輔</option>
                      <option value="ohga">大賀 成真</option>
                      <option value="ota">大田 俊吾</option>
                      <option value="hayamizu">早水 佑希</option>
                      <option value="takita">田北 祐哉</option>
                      <option value="morikawa">森川 大聖</option>
                      <option value="sato">佐藤 菜南</option>
                      <option value="sakakibara">榊原 陽和</option>
                      <option value="oishi">大石 和奈</option>
                      <option value="shih-yajin">施 雅今</option>
                      <option value="suda">須田 恵子</option>
                      <option value="kudou">工藤 祐介</option>
                    </select>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div>
                    <h3>Year:</h3>
                    <select value={filterState.year} onChange={onYearChanged}>
                      <option>ALL</option>
                      <option>2024</option>
                      <option>2023</option>
                      <option>2022</option>
                      <option>2021</option>
                      <option>2020</option>
                      <option>2019</option>
                      <option>2018</option>
                      <option>2017</option>
                      <option>2016</option>
                      <option>2015</option>
                      <option>2014</option>
                      <option>2013</option>
                      <option>2012</option>
                      <option>2011</option>
                      <option value="2010">2010以前</option>
                    </select>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div>
                    <h3>Category:</h3>
                    <select value={filterState.category} onChange={onCategoryChanged}>
                      <option>ALL</option>
                      <option>EVENT</option>
                      <option>EXHIBITION</option>
                      <option>PROMOTION</option>
                      <option>EDUCATION</option>
                      <option>INDEPENDENT PRODUCTION</option>
                      <option>DOCUMENTARY</option>
                      <option>OTHERS</option>
                    </select>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid className="listContainer">
          <Row>
            {filteredWorks.length !== 0 ?
              filteredWorks.map(({ node }) => (
                <WorksEntry node={node} key={node.id} />
              ))
              : <p className="text-center">No Entry</p>
            }
          </Row>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default WorksPage;

export const query = graphql`
  query {
    worksJson: allWorksJson {
      edges {
        node {
          category
          client
          imagePosition
          members
          slug
          title
          year
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 960)
            }
          }
          id
        }
      }
    }
    membersJson: allMembersJson {
      edges {
        node {
          slug
          name
          en_name
        }
      }
    }
  }
`;
